<template>
<div class="news">
  <b-modal id="modal-news" size="xl" :visible="true" hide-header hide-footer>
    <div class="m-4">

    <div class="card mt-3">
      <div class="row no-gutters">
        <div class="col-md-4">
          <img src="@/assets/images/custom/custom_boston_01.png" class="card-img" alt="...">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">数千・数百名の社員に統一規格のバーチャル背景を提供できます</h5>
            <p class="card-text">カスタマイズ事例を公開いたしました。<br>よくお問い合わせいただく質問に対しての回答も公開しております。ご検討中の方はご参考になさってください。<br>詳しくは<router-link :to="{name: 'custom'}">カスタマイズの要望</router-link>のページをご覧ください。</p>
            <p class="card-text">
              <b-button block variant="info" :to="{name: 'custom'}">カスタマイズの要望</b-button>
            </p>
          </div>
        </div>
      </div>
    </div>

      <div class="card mt-3">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img src="@/assets/images/nicecamera.png" class="card-img" alt="...">
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">アバターアプリ『NICE CAMERA』を開発しました</h5>
              <p class="card-text">顔の動きや表情、上半身の動きをリアルタイムで読み取り、アバターに反映させることができる革新的なアプリです。</p>
              <p class="card-text">
                <b-button block variant="info" href="https://nicecamera.kidsplates.jp" target="_blank">NICE CAMERA 公式サイトへ</b-button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <b-button variant="primary" size="lg" class="mt-4 shadow" @click="$bvModal.hide('modal-news')">さっそく背景を作る</b-button>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
// import { saveAs } from 'file-saver'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  mounted() {
    this.$bvModal.show('modal-news');
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.news {}
</style>
