<template>
  <div class="control py-3">
    <b-container fluid>
      <b-row class="px-3">
        <b-col align-self="end" v-if="!setting.hideProduceBy">
          <a href="https://kidsplates.jp/"><img style="width: 160px;" src="@/assets/images/kidsplates_logo.png" alt=""></a>
        </b-col>
        <b-col class="text-center">
          <div v-if="setting.appId !== 'mynavi'">
            <b-button variant="primary" size="lg" class="shadow" v-b-modal.modal-download>バーチャル名刺背景生成</b-button>
          </div>
          <div v-if="setting.appId == 'mynavi'">
            <b-button variant="primary" size="lg" class="shadow" v-b-modal.modal-download>バーチャル背景生成</b-button>
          </div>
        </b-col>
        <b-col align-self="end" class="text-right" v-if="!setting.hideProduceBy">
          &copy; 2020 Kidsplates.
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="modal-download" hide-header hide-footer>
      <div class="my-4">
        <p class="m-0 text-center font-weight-bold">生成後は右クリックで画像を保存してください</p>

        <div class="pt-4 text-center" v-if="setting.appId !== 'mynavi'">
          <b-button id="download" size="lg" class="shadow" @click="download" variant="primary">バーチャル名刺背景生成</b-button>
        </div>
        <div class="pt-4 text-center" v-if="setting.appId == 'mynavi'">
          <b-button id="download" size="lg" class="shadow" @click="download" variant="primary">バーチャル背景生成</b-button>
        </div>

        <div v-if="setting.appId == 'default'">
          <amp-ad width="100vw" height="320"
              type="adsense"
              data-ad-client="ca-pub-8110296154850783"
              data-ad-slot="8651743186"
              data-auto-format="rspv"
              data-full-width="">
            <div overflow=""></div>
          </amp-ad>
        </div>
        <p class="m-0 pt-4 text-center" v-if="setting.appId !== 'mynavi'">#バーチャル名刺背景ジェネレーター<br>でSNSでシェアしましょう！</p>
        <p class="m-0 pt-4 text-center" v-if="setting.appId == 'mynavi'">#マイナビバーチャル背景<br>でSNSでシェアしましょう！</p>
      </div>

      <!-- <div class="my-4 pt-4 border-top" v-if="setting.appId == 'default'">
        <p class="m-0 text-center font-weight-bold">利用者向けのアンケート</p>
        <p class="m-0 pt-4 text-center">いつもご利用いただきありがとうございます。<br>今後のバーチャル名刺背景ジェネレーターの展開に向け、<br>皆さんのご意見を参考にさせていただきたく、<br>アンケートへのご協力をお願いいたします。</p>
        <div class="pt-4 text-center">
          <b-button href="https://forms.gle/qA1GAeXNzqrR2abF8" target="_blank" variant="secondary" size="lg" class="shadow">利用者向けのアンケート</b-button>
        </div>
      </div> -->
    </b-modal>
  </div>
</template>

<script>
// import { saveAs } from 'file-saver'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
  },
  methods: {
    download: function () {
      let canvas = document.getElementById('canvas')

      // ファイル名指定で強制ダウンロード
      // canvas.toBlob(function(blob) {
      //   saveAs(blob, "virtualmeishihaikei.png")
      // }, 'image/png')

      // 新規タブで画像を表示
      let w = window.open('about:blank')
      w.document.write("<img src='" + canvas.toDataURL() + "'/>")
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.control {}
</style>
