<template>
<div class="h-100">
  <News v-if="setting.appId == 'default'" />

  <div class="row no-gutters" id="appArea">
    <div class="col-xl-7 col-lg-7 col-12 h-100 overflow-auto">
      <Preview />
    </div>
    <div class="col-xl-5 col-lg-5 col-12 h-100 overflow-auto bg-light">
      <Editor />
    </div>
  </div>
  <div class="bg-white border-top">
    <Control />
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import News from '@/components/Generator_News.vue'
import Preview from '@/components/Generator_Preview.vue'
import Control from '@/components/Generator_Control.vue'
import Editor from '@/components/Generator_Editor.vue'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
  },
  components: {
    News,
    Preview,
    Control,
    Editor,
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width:992px) {
  #appArea {
    height: calc(100% - 79px);
  }
}
</style>
